@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-white-100: hsl(0, 0%, 100%);
  --color-white-200: hsl(0, 0%, 95%);
  --color-white-300: hsl(0, 0%, 85%);
  --color-white-400: hsl(0, 0%, 75%);
  --color-white-500: hsl(0, 0%, 65%);
  --color-white-600: hsl(0, 0%, 55%);

  --color-black-100: hsl(210, 17%, 12%);
  --color-black-200: hsl(210, 17%, 10%);
  --color-black-300: hsl(210, 16%, 8%);
  --color-black-400: hsl(210, 16%, 6%);
  --color-black-500: hsl(210, 15%, 4%);
  --color-black-600: hsl(210, 15%, 2%);

  --text-tiny: clamp(0.84rem, calc(0.12vw + 0.8rem), 0.89rem);
  --text-small: clamp(0.93rem, calc(0.13vw + 0.91rem), 1rem);
  --text-base: clamp(1.05rem, calc(0.15vw + 1.01rem), 1.12rem);
  --text-medium: clamp(1.18rem, calc(0.17vw + 1.14rem), 1.26rem);
  --text-large: clamp(1.32rem, calc(0.19vw + 1.28rem), 1.41rem);

  --shadow-small: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

html {
  scroll-behavior: smooth;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.Toastify__toast-theme--dark {
  background: #1a1a1a !important;
  border-radius: 12px !important;
}
.Toastify__toast-theme--light {
  background: #fff !important;
  border-radius: 12px !important;
}

.Toastify__toast--success {
  border: 1px solid #28cd41 !important;
}

.Toastify__toast--error {
  border: 1px solid #e8271c !important;
}

.Toastify__toast--warning {
  border: 1px solid #ff9500 !important;
}

.Toastify__toast--info {
  border: 1px solid #0065d1 !important;
}

.Toastify__toast-icon {
  display: none !important;
}

.Toastify__close-button {
  display: none !important;
}

.Toastify__progress-bar {
  display: none;
}

.Toastify__progress-bar--bg {
  display: none;
}

.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light {
  background-color: #20242d !important;
}
